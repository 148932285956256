export const CREATE = "create";
export const UPDATE = "update";
export const LIST = "list";
export const ITEMS = "items";
export const FILES = "files";
export const DISPLAY = "display";
export const REDIRECT = "redirect";
export const REDIRECT_MOBILE = "redirect_mobile";
export const SUBSCRIBED = "subscribed";
export const THREADS = "threads";
export const LATEST = "latest";
export const FEATURED = "featured";
export const SELF = "self";
export const RELEASES = "releases";
export const INFO = "info";
export const CHARTS = "charts";
export const CREDENTIALS = "credentials";
export const CREDENTIAL_FORMS = "credential-forms";
export const PIPELINES = "pipelines";
export const LISTENERS = "listeners";
export const PROMPTS = "prompts";
export const WEBHOOKS = "webhooks";
export const SITES = "sites";
export const SEARCH = "search";
export const PAGE = "page";
export const TRASH = "trash";
export const GROUPS = "groups";
export const STATUS = "status";
export const TRENDING = "trending";
export const HUB = "hub";
export const CSV = "csv";
export const CSV_REPORTS = "csv-reports";
export const DATA_STORES = "data-stores";
export const EMAIL = "email";
export const PLUGINS = "plugins";
export const BLUEPRINTS = "blueprints";
export const DOWNSTREAM = "downstream";
export const RUN_NOW = "run-now";
export const INSTALL = "install";
export const INSTALLATIONS = "installations";
export const FORMS = "forms";
export const FORM_SUCCESS = "form-success";
export const SUBMISSIONS = "submissions";
export const INSTANCES = "instances";
export const SEQUENCES = "sequences";
export const JOBS = `jobs`;
export const CONNECTOR = "connector";
export const EXPORT = "export";
export const UPDATE_PLUGINS = "update-plugins";
export const UPDATE_DEPENDENCIES = "update-dependencies";
export const PROJECTS = "projects";
export const UNPACK = "unpack";
export const SAVED = "saved";
export const STATS = "stats";
export const ENTITIES = "entities";
export const QR_CODES = "qr-codes";
export const REPOSITORIES = "repositories";
export const FORM_CREATOR = "form-creator";
export const DYNAMIC_FORMS = "dynamic-forms";
export const SPECIFICATIONS = "specifications";
export const DASHBOARD = "dashboard";

export const ROUTE_MAIN = `/`;
export const ROUTE_MAIN_PAGE_CATEGORY = `/${PAGE}/:categorySlug`;
export const ROUTE_MAIN_PAGE_SUBCATEGORY = `/${PAGE}/:categorySlug/:subcategorySlug`;
export const ROUTE_LOGIN = `/login`;
export const ROUTE_LOGOUT = `/logout`;
export const ROUTE_SIGNUP = `/signup`;
export const ROUTE_NOT_FOUND = `/not-found`;
export const ROUTE_SETUP = `/setup`;
export const ROUTE_CREDENTIAL_QR_CODES = `/${QR_CODES}`;

export const ROUTE_SITES = `/${SITES}`;
export const ROUTE_SITES_MAIN = `/${SITES}/main`;
export const ROUTE_SITES_REGISTER = `/${SITES}/register`;
export const ROUTE_SITES_REGISTER_SUCCESS = `/${SITES}/register-success`;
export const ROUTE_SITES_DISPLAY_MOBILE = `/${SITES}/${DISPLAY}/:siteId`;
export const ROUTE_SITES_DISPLAY_MOBILE_CATEGORY = `${ROUTE_SITES_DISPLAY_MOBILE}/${PAGE}/:categorySlug`;
export const ROUTE_SITES_DISPLAY_MOBILE_SUBCATEGORY = `${ROUTE_SITES_DISPLAY_MOBILE_CATEGORY}/:subcategorySlug`;

export const ROUTE_PRIVACY_POLICY = "/privacy-policy";

export const ROUTE_APP_PREFIX = `/app`;
export const ROUTE_APP_SITES_LIST = `${ROUTE_APP_PREFIX}/${SITES}/${LIST}`;
export const ROUTE_APP_SITES_SEARCH = `${ROUTE_APP_PREFIX}/${SITES}/${SEARCH}`;
export const ROUTE_APP_PROJECTS_LIST = `${ROUTE_APP_PREFIX}/${PROJECTS}/${LIST}`;
export const ROUTE_APP_PROJECTS_STATS = `${ROUTE_APP_PREFIX}/${PROJECTS}/${STATS}`;
export const ROUTE_APP_SEARCH = `${ROUTE_APP_PREFIX}/${SEARCH}`;
export const ROUTE_APP_PROJECTS_DISPLAY = `${ROUTE_APP_PREFIX}/${PROJECTS}/:projectId/${DISPLAY}`;
export const ROUTE_APP_PROJECTS_MAIN = `${ROUTE_APP_PREFIX}/${PROJECTS}/:projectId`;
export const ROUTE_APP_PLUGIN_DISPLAY = `${ROUTE_APP_PREFIX}/${PLUGINS}/:hubPluginId/${DISPLAY}`;
export const ROUTE_APP_BLUEPRINT_INSTALL_ONE_CLICK = `${ROUTE_APP_PREFIX}/${BLUEPRINTS}/:hubBlueprintMetaId/${INSTALL}`;
export const ROUTE_APP_PIPELINES_MAIN = `${ROUTE_APP_PREFIX}/${PIPELINES}/:pipelineId`;
export const ROUTE_APP_WEBHOOKS_MAIN = `${ROUTE_APP_PREFIX}/${WEBHOOKS}/:webhookId`;
export const ROUTE_APP_LISTENERS_MAIN = `${ROUTE_APP_PREFIX}/${LISTENERS}/:listenerId`;
export const ROUTE_APP_PROMPTS_MAIN = `${ROUTE_APP_PREFIX}/${FORMS}/:promptId`;
export const ROUTE_APP_PIPELINE_JOBS_MAIN = `${ROUTE_APP_PREFIX}/${PIPELINES}/${JOBS}/:pipelineJobId`;
export const ROUTE_APP_BLUEPRINT_INSTALLATIONS_DISPLAY = `${ROUTE_APP_PREFIX}/${BLUEPRINTS}/:hubBlueprintMetaId/${INSTALLATIONS}/:blueprintInstallationId/${DISPLAY}`;
export const ROUTE_APP_BLUEPRINT_INSTALLATIONS_UNPACK = `${ROUTE_APP_PREFIX}/${BLUEPRINTS}/:hubBlueprintMetaId/${INSTALLATIONS}/:blueprintInstallationId/${UNPACK}`;
export const ROUTE_APP_PIPELINES_PROGRESS_VISUAL = `${ROUTE_APP_PREFIX}/${PIPELINES}/:pipelineId/${RUN_NOW}`;

export const ROUTE_DASHBOARD_PREFIX = `/${DASHBOARD}`;
export const ROUTE_DASHBOARD_DOMAINS = `${ROUTE_DASHBOARD_PREFIX}/domains`;

export const ROUTE_DASHBOARD_MAIN = `${ROUTE_DASHBOARD_PREFIX}/main`;
export const ROUTE_DASHBOARD_MAIN_MOBILE = `${ROUTE_DASHBOARD_MAIN}/mobile`;

export const ROUTE_DASHBOARD_TILE_LIST = `${ROUTE_DASHBOARD_PREFIX}/tiles/${LIST}`;
export const ROUTE_DASHBOARD_TILE_CREATE = `${ROUTE_DASHBOARD_PREFIX}/tiles/${CREATE}`;
export const ROUTE_DASHBOARD_TILE_UPDATE = `${ROUTE_DASHBOARD_PREFIX}/tiles/:id/${UPDATE}`;

export const ROUTE_DASHBOARD_DNS = `${ROUTE_DASHBOARD_PREFIX}/dns`;
export const ROUTE_DASHBOARD_GENERAL = `${ROUTE_DASHBOARD_PREFIX}/general`;
export const ROUTE_DASHBOARD_CATEGORIES = `${ROUTE_DASHBOARD_PREFIX}/categories`;
export const ROUTE_DASHBOARD_CATEGORIES_META = `${ROUTE_DASHBOARD_CATEGORIES}/meta`;
export const ROUTE_DASHBOARD_CATEGORIES_META_UPDATE = `${ROUTE_DASHBOARD_CATEGORIES_META}/:categoryMetaId`;
export const ROUTE_DASHBOARD_THEME = `${ROUTE_DASHBOARD_PREFIX}/theme`;
export const ROUTE_DASHBOARD_SEO = `${ROUTE_DASHBOARD_PREFIX}/seo`;
export const ROUTE_DASHBOARD_SOCIAL = `${ROUTE_DASHBOARD_PREFIX}/social`;
export const ROUTE_DASHBOARD_STORAGE = `${ROUTE_DASHBOARD_PREFIX}/storage`;
export const ROUTE_DASHBOARD_BANNERS = `${ROUTE_DASHBOARD_PREFIX}/banners`;
export const ROUTE_DASHBOARD_SETTINGS = `${ROUTE_DASHBOARD_PREFIX}/settings`;
export const ROUTE_DASHBOARD_PROFILE = `${ROUTE_DASHBOARD_PREFIX}/profile`;
export const ROUTE_DASHBOARD_SITE_SUBSCRIPTIONS = `${ROUTE_DASHBOARD_PREFIX}/${SITES}/subscriptions`;
export const ROUTE_DASHBOARD_SITE_SUBSCRIPTIONS_SEARCH_MOBILE = `${ROUTE_DASHBOARD_PREFIX}/${SITES}/subscriptions/${SEARCH}`;

export const ROUTE_DIRECTORY_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/directory`;
export const ROUTE_DIRECTORY_MAIN = `${ROUTE_DIRECTORY_PREFIX}/main`;
export const ROUTE_DIRECTORY_TREE = `${ROUTE_DIRECTORY_PREFIX}/tree`;
export const ROUTE_DIRECTORY_ANALYTICS = `${ROUTE_DIRECTORY_PREFIX}/analytics`;

// Media
export const ROUTE_MEDIA_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/media`;
export const ROUTE_MEDIA_IMAGES = `${ROUTE_MEDIA_PREFIX}/images`;
export const ROUTE_MEDIA_BANNERS = `${ROUTE_MEDIA_PREFIX}/banners`;

// CSV
export const ROUTE_CSV_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${CSV}`;
export const ROUTE_CSV_UPDATE = `${ROUTE_CSV_PREFIX}/:csvId`;

// Pipelines
export const ROUTE_PIPELINES_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${PIPELINES}`;
export const ROUTE_PIPELINES_LIST = `${ROUTE_PIPELINES_PREFIX}/${LIST}`;
export const ROUTE_PIPELINES_CONNECTOR = `${ROUTE_PIPELINES_PREFIX}/${CONNECTOR}`;
export const ROUTE_PIPELINES_PROGRESS_VISUAL = `${ROUTE_PIPELINES_PREFIX}/:pipelineId/${RUN_NOW}`;
export const ROUTE_PIPELINES_LIST_STATUS = `${ROUTE_PIPELINES_PREFIX}/${LIST}/:${STATUS}`;
export const ROUTE_PIPELINES_CREATE = `${ROUTE_PIPELINES_PREFIX}/${CREATE}`;
export const ROUTE_PIPELINES_UPDATE = `${ROUTE_PIPELINES_PREFIX}/:pipelineId`;

export const ROUTE_PIPELINE_GROUPS_PREFIX = `${ROUTE_PIPELINES_PREFIX}/${GROUPS}`;
export const ROUTE_PIPELINE_GROUPS_LIST = `${ROUTE_PIPELINE_GROUPS_PREFIX}/${LIST}`;
export const ROUTE_PIPELINE_GROUPS_UPDATE = `${ROUTE_PIPELINE_GROUPS_PREFIX}/:pipelineGroupId`;
export const ROUTE_PIPELINE_GROUPS_DISPLAY = `${ROUTE_PIPELINE_GROUPS_PREFIX}/:pipelineGroupId/${DISPLAY}`;
export const ROUTE_PIPELINE_GROUPS_ENTITIES = `${ROUTE_PIPELINE_GROUPS_PREFIX}/:pipelineGroupId/${ENTITIES}`;

export const ROUTE_PIPELINE_NODES_ROOT = `nodes`;
export const ROUTE_PIPELINE_NODES_CREATION_PATH = `${ROUTE_PIPELINE_NODES_ROOT}/create`;
export const ROUTE_PIPELINE_NODES_CREATE = `${ROUTE_PIPELINES_UPDATE}/${ROUTE_PIPELINE_NODES_CREATION_PATH}`;
export const ROUTE_PIPELINE_NODES_UPDATE = `${ROUTE_PIPELINES_UPDATE}/${ROUTE_PIPELINE_NODES_ROOT}/:pipelineNodeId`;
export const ROUTE_PIPELINE_NODES_DOWNSTREAM = `${ROUTE_PIPELINE_NODES_UPDATE}/${DOWNSTREAM}`;
export const ROUTE_PIPELINE_NODES_ADD_CHILD_ROOT = `add-child`;
export const ROUTE_PIPELINE_NODES_ADD_CHILD = `${ROUTE_PIPELINES_UPDATE}/${ROUTE_PIPELINE_NODES_ROOT}/:pipelineNodeId/${ROUTE_PIPELINE_NODES_ADD_CHILD_ROOT}`;
export const ROUTE_PIPELINE_NODE_CONDITIONS_ROOT = `conditions`;
export const ROUTE_PIPELINE_NODE_CONDITIONS_CREATE = `${ROUTE_PIPELINE_NODES_UPDATE}/${ROUTE_PIPELINE_NODE_CONDITIONS_ROOT}/create`;
export const ROUTE_PIPELINE_NODE_CONDITIONS_UPDATE = `${ROUTE_PIPELINE_NODES_UPDATE}/${ROUTE_PIPELINE_NODE_CONDITIONS_ROOT}/:pipelineNodeConditionId`;

export const ROUTE_PIPELINE_JOBS_ROOT = JOBS;
export const ROUTE_PIPELINE_JOBS_PREFIX = `${ROUTE_PIPELINES_PREFIX}/:pipelineId/${JOBS}`;
export const ROUTE_PIPELINE_JOBS_PAGE = `${ROUTE_PIPELINE_JOBS_PREFIX}/:pipelineJobId`;

export const ROUTE_PIPELINES_LISTENERS_PREFIX = `${ROUTE_PIPELINES_PREFIX}/${LISTENERS}`;
export const ROUTE_PIPELINES_LISTENERS_LIST = `${ROUTE_PIPELINES_LISTENERS_PREFIX}/${LIST}`;
export const ROUTE_PIPELINES_LISTENERS_CREATE = `${ROUTE_PIPELINES_LISTENERS_PREFIX}/${CREATE}`;
export const ROUTE_PIPELINES_LISTENERS_UPDATE = `${ROUTE_PIPELINES_LISTENERS_PREFIX}/:listenerId`;
export const ROUTE_PIPELINES_LISTENERS_JOBS_MAIN = `${ROUTE_PIPELINES_LISTENERS_PREFIX}/:listenerId/${ROUTE_PIPELINE_JOBS_ROOT}/:listenerJobId`;

export const ROUTE_PIPELINES_WEBHOOKS_PREFIX = `${ROUTE_PIPELINES_PREFIX}/${WEBHOOKS}`;
export const ROUTE_PIPELINES_WEBHOOKS_LIST = `${ROUTE_PIPELINES_WEBHOOKS_PREFIX}/${LIST}`;
export const ROUTE_PIPELINES_WEBHOOKS_CREATE = `${ROUTE_PIPELINES_WEBHOOKS_PREFIX}/${CREATE}`;
export const ROUTE_PIPELINES_WEBHOOKS_UPDATE = `${ROUTE_PIPELINES_WEBHOOKS_PREFIX}/:webhookId`;

// Repository
export const ROUTE_REPOSITORY_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${REPOSITORIES}`;
export const ROUTE_REPOSITORY_LIST = `${ROUTE_REPOSITORY_PREFIX}/${LIST}`;
export const ROUTE_REPOSITORY_CREATE = `${ROUTE_REPOSITORY_PREFIX}/${CREATE}`;
export const ROUTE_REPOSITORY_DISPLAY = `${ROUTE_REPOSITORY_PREFIX}/:repositoryId`;
export const ROUTE_REPOSITORY_ITEMS = `${ROUTE_REPOSITORY_DISPLAY}/${ITEMS}`;
export const ROUTE_REPOSITORY_FILES = `${ROUTE_REPOSITORY_DISPLAY}/${FILES}`;
export const ROUTE_REPOSITORY_CHARTS = `${ROUTE_REPOSITORY_DISPLAY}/${CHARTS}`;
export const ROUTE_REPOSITORY_CHART_CREATE = `${ROUTE_REPOSITORY_DISPLAY}/${CHARTS}/${CREATE}`;
export const ROUTE_REPOSITORY_CSV_CREATE = `${ROUTE_REPOSITORY_DISPLAY}/${CSV}/${CREATE}`;
export const ROUTE_REPOSITORY_CSV_UPDATE = `${ROUTE_REPOSITORY_DISPLAY}/${CSV}/:csvId`;
export const ROUTE_REPOSITORY_CSV_DISPLAY = `${ROUTE_REPOSITORY_DISPLAY}/${CSV}/:csvId/${DISPLAY}`;
export const ROUTE_REPOSITORY_CSV_REPORT_CREATE = `${ROUTE_REPOSITORY_DISPLAY}/${CSV_REPORTS}/${CREATE}`;
export const ROUTE_REPOSITORY_CSV_REPORT_UPDATE = `${ROUTE_REPOSITORY_DISPLAY}/${CSV_REPORTS}/:csvReportId`;
export const ROUTE_REPOSITORY_CSV_REPORT_DISPLAY = `${ROUTE_REPOSITORY_DISPLAY}/${CSV_REPORTS}/:csvReportId/${DISPLAY}`;
export const ROUTE_REPOSITORY_CHART_UPDATE = `${ROUTE_REPOSITORY_DISPLAY}/${CHARTS}/:chartId`;
export const ROUTE_REPOSITORY_ITEM = `${ROUTE_REPOSITORY_ITEMS}/:repositoryItemId`;
export const ROUTE_REPOSITORY_DATA_STORE_CREATE = `${ROUTE_REPOSITORY_DISPLAY}/${DATA_STORES}/${CREATE}`;
export const ROUTE_REPOSITORY_DATA_STORE_UPDATE = `${ROUTE_REPOSITORY_DISPLAY}/${DATA_STORES}/:dataStoreId`;
export const ROUTE_REPOSITORY_DATA_STORE_DISPLAY = `${ROUTE_REPOSITORY_DISPLAY}/${DATA_STORES}/:dataStoreId/${DISPLAY}`;

// User
export const ROUTE_USER_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/users`;
export const ROUTE_USER_LIST = `${ROUTE_USER_PREFIX}/${LIST}`;
export const ROUTE_USER_CREATE = `${ROUTE_USER_PREFIX}/${CREATE}`;

// Credentials
export const ROUTE_CREDENTIALS_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${CREDENTIALS}`;
export const ROUTE_CREDENTIALS_LIST = `${ROUTE_CREDENTIALS_PREFIX}/${LIST}`;
export const ROUTE_CREDENTIALS_CREATE = `${ROUTE_CREDENTIALS_PREFIX}/${CREATE}`;
export const ROUTE_CREDENTIALS_UPDATE = `${ROUTE_CREDENTIALS_PREFIX}/:credentialId`;
export const ROUTE_CREDENTIAL_FORMS_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${CREDENTIALS}/${FORM_CREATOR}`;
export const ROUTE_CREDENTIAL_FORMS_CREATE = `${ROUTE_CREDENTIAL_FORMS_PREFIX}/${CREATE}`;
export const ROUTE_CREDENTIAL_FORMS_UPDATE = `${ROUTE_CREDENTIAL_FORMS_PREFIX}/:credentialFormId`;

// Entry
export const ROUTE_ENTRY_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/entries`;
export const ROUTE_ENTRY_LIST = `${ROUTE_ENTRY_PREFIX}/${LIST}`;
export const ROUTE_ENTRY_TRASH = `${ROUTE_ENTRY_PREFIX}/${TRASH}`;
export const ROUTE_ENTRY_TRENDING = `${ROUTE_ENTRY_PREFIX}/${TRENDING}`;
export const ROUTE_ENTRY_CREATE = `${ROUTE_ENTRY_PREFIX}/${CREATE}`;
export const ROUTE_ENTRY_UPDATE = `${ROUTE_ENTRY_PREFIX}/:entryId`;
export const ROUTE_ENTRY_PREVIEW_SUFFIX = `preview`;
export const ROUTE_ENTRY_PREVIEW = `${ROUTE_ENTRY_UPDATE}/${ROUTE_ENTRY_PREVIEW_SUFFIX}`;

// Featured Group
export const ROUTE_FEATURED_GROUP_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${FEATURED}`;
export const ROUTE_FEATURED_GROUP_LIST_PREFIX = `${ROUTE_FEATURED_GROUP_PREFIX}/${LIST}`;

// Featured Group List Pages
export const ROUTE_FEATURED_GROUP_LIST_SELF = `${ROUTE_FEATURED_GROUP_LIST_PREFIX}/${SELF}`;
export const ROUTE_FEATURED_GROUP_LIST_LATEST = `${ROUTE_FEATURED_GROUP_LIST_PREFIX}/${LATEST}`;
export const ROUTE_FEATURED_GROUP_LIST_SUBSCRIBED = `${ROUTE_FEATURED_GROUP_LIST_PREFIX}/${SUBSCRIBED}`;
export const ROUTE_FEATURED_GROUP_LIST_RELEASES = `${ROUTE_FEATURED_GROUP_LIST_PREFIX}/${RELEASES}`;

// Individual pages for one group
export const ROUTE_FEATURED_GROUP_SUBSCRIPTION_INFO = `${ROUTE_FEATURED_GROUP_PREFIX}/:featuredGroupId/${INFO}`;

export const ROUTE_FEATURED_GROUP_CREATE = `${ROUTE_FEATURED_GROUP_PREFIX}/${CREATE}`;
export const ROUTE_FEATURED_GROUP_UPDATE = `${ROUTE_FEATURED_GROUP_PREFIX}/:featuredGroupId`;
export const ROUTE_FEATURED_GROUP_DISPLAY = `${ROUTE_FEATURED_GROUP_PREFIX}/:featuredGroupId/${DISPLAY}`;

// Threads
export const ROUTE_FEATURED_GROUP_THREAD_CREATE = `${ROUTE_FEATURED_GROUP_UPDATE}/${THREADS}/${CREATE}`;
export const ROUTE_FEATURED_GROUP_THREAD_UPDATE = `${ROUTE_FEATURED_GROUP_UPDATE}/${THREADS}/:featuredThreadId`;

// Thread Charts
export const ROUTE_FEATURED_THREAD_CHARTS = `${ROUTE_FEATURED_GROUP_THREAD_UPDATE}/${CHARTS}`;
export const ROUTE_FEATURED_THREAD_CHART_CREATE = `${ROUTE_FEATURED_THREAD_CHARTS}/${CREATE}`;
export const ROUTE_FEATURED_THREAD_CHART_UPDATE = `${ROUTE_FEATURED_THREAD_CHARTS}/:chartId`;

// Releases
export const ROUTE_FEATURED_THREAD_RELEASES = `${ROUTE_FEATURED_GROUP_THREAD_UPDATE}/${RELEASES}`;

export const ROUTE_FEATURED_THREAD_RELEASE = `${ROUTE_FEATURED_THREAD_RELEASES}/:featuredThreadReleaseId`;

// Items
export const ROUTE_FEATURED_THREAD_RELEASE_ITEMS = `${ROUTE_FEATURED_THREAD_RELEASE}/${ITEMS}`;
export const ROUTE_FEATURED_THREAD_RELEASE_ITEM_DETAILED = `${ROUTE_FEATURED_THREAD_RELEASE}/${ITEMS}/:featuredItemId`;

// Plugins
export const ROUTE_PLUGINS_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/plugins`;
export const ROUTE_PLUGINS_LIST = `${ROUTE_PLUGINS_PREFIX}/${LIST}`;
export const ROUTE_PLUGINS_HUB = `${ROUTE_PLUGINS_PREFIX}/${HUB}`;
export const ROUTE_PLUGINS_DISPLAY = `${ROUTE_PLUGINS_PREFIX}/:hubPluginId/${DISPLAY}`;
export const ROUTE_PLUGIN_BLUEPRINTS_PREFIX = `${ROUTE_PLUGINS_PREFIX}/:hubPluginId/${BLUEPRINTS}`;

// SMTP
export const ROUTE_SMTP_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${EMAIL}`;
export const ROUTE_SMTP_ACCOUNT_LIST = `${ROUTE_SMTP_PREFIX}/${LIST}`;
export const ROUTE_SMTP_ACCOUNT_CREATE = `${ROUTE_SMTP_PREFIX}/${CREATE}`;
export const ROUTE_SMTP_ACCOUNT_UPDATE = `${ROUTE_SMTP_PREFIX}/:smtpAccountId`;

// Blueprints
export const ROUTE_BLUEPRINT_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${BLUEPRINTS}`;
export const ROUTE_BLUEPRINT_INSTALLATIONS_LIST = `${ROUTE_DASHBOARD_PREFIX}/${BLUEPRINTS}/:hubBlueprintMetaId/${INSTALLATIONS}/${LIST}`;
export const ROUTE_BLUEPRINT_INSTALLATIONS_DISPLAY = `${ROUTE_DASHBOARD_PREFIX}/${BLUEPRINTS}/:hubBlueprintMetaId/${INSTALLATIONS}/:blueprintInstallationId/${DISPLAY}`;
export const ROUTE_BLUEPRINT_INSTALLATIONS_UNPACK = `${ROUTE_DASHBOARD_PREFIX}/${BLUEPRINTS}/:hubBlueprintMetaId/${INSTALLATIONS}/:blueprintInstallationId/${UNPACK}`;
export const ROUTE_BLUEPRINT_DISPLAY = `${ROUTE_DASHBOARD_PREFIX}/${BLUEPRINTS}/:blueprintMetaId/${DISPLAY}`;
export const ROUTE_BLUEPRINT_INSTALL = `${ROUTE_DASHBOARD_PREFIX}/${BLUEPRINTS}/:hubBlueprintMetaId/${INSTALL}`;
export const ROUTE_BLUEPRINT_LIST = `${ROUTE_BLUEPRINT_PREFIX}/${LIST}`;
export const ROUTE_BLUEPRINT_EXPORT = `${ROUTE_BLUEPRINT_PREFIX}/${EXPORT}`;
export const ROUTE_BLUEPRINT_UPDATE_DEPENDENCIES = `${ROUTE_BLUEPRINT_PREFIX}/${UPDATE_DEPENDENCIES}`;
export const ROUTE_BLUEPRINT_INSTALLATION_UPDATE = `${ROUTE_BLUEPRINT_PREFIX}/:blueprintInstallationId`;
export const ROUTE_BLUEPRINTS_SAVED_LIST = `${ROUTE_BLUEPRINT_PREFIX}/${PROJECTS}/:projectId/${SAVED}/${LIST}`;
export const ROUTE_BLUEPRINTS_SAVED_UPDATE = `${ROUTE_BLUEPRINT_PREFIX}/${PROJECTS}/:projectId/${SAVED}/:blueprintSavedId`;
export const ROUTE_BLUEPRINTS_SAVED_CREATE = `${ROUTE_BLUEPRINT_PREFIX}/${PROJECTS}/:projectId/${SAVED}/${CREATE}`;

// Prompt
export const ROUTE_PROMPTS_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${FORMS}`;
export const ROUTE_PROMPTS_LIST = `${ROUTE_PROMPTS_PREFIX}/${LIST}`;
export const ROUTE_PROMPTS_CREATE = `${ROUTE_PROMPTS_PREFIX}/${CREATE}`;
export const ROUTE_PROMPTS_UPDATE = `${ROUTE_PROMPTS_PREFIX}/:promptId`;
export const ROUTE_PROMPTS_DOWNSTREAM = `${ROUTE_PROMPTS_PREFIX}/:promptId/${DOWNSTREAM}`;
export const ROUTE_PROMPTS_SUBMISSIONS_LIST = `${ROUTE_PROMPTS_PREFIX}/:promptId/${SUBMISSIONS}/${LIST}`;
export const ROUTE_PROMPTS_SUBMISSIONS_MAIN = `${ROUTE_PROMPTS_PREFIX}/:promptId/${SUBMISSIONS}/:promptRecipientId`;
export const ROUTE_PROMPTS_INSTANCES_MAIN = `${ROUTE_PROMPTS_PREFIX}/:promptId/${INSTANCES}/:promptInstanceId`;
export const ROUTE_PROMPTS_DISPLAY = `${ROUTE_PROMPTS_PREFIX}/:promptId/${DISPLAY}`;
export const ROUTE_PROMPTS_DISPLAY_NO_DASHBOARD_PREFIX = `/${FORMS}`;
export const ROUTE_PROMPTS_DISPLAY_NO_DASHBOARD = `${ROUTE_PROMPTS_DISPLAY_NO_DASHBOARD_PREFIX}/:promptId`;
export const ROUTE_PROMPTS_DISPLAY_NO_DASHBOARD_ALT = `/${PROMPTS}/:promptId`;

// Credential Form
export const ROUTE_CREDENTIAL_FORMS_DISPLAY = `/${CREDENTIAL_FORMS}/:credentialFormId`;

// Project
export const ROUTE_PROJECTS_PREFIX = `${ROUTE_DASHBOARD_PREFIX}/${PROJECTS}`;
export const ROUTE_PROJECTS_CREATE = `${ROUTE_PROJECTS_PREFIX}/${CREATE}`;
export const ROUTE_PROJECTS_MAIN = `${ROUTE_DASHBOARD_PREFIX}/${PROJECTS}/:projectId`;

// Sequences
export const ROUTE_PIPELINE_JOB_SEQUENCES_LIST = `${ROUTE_PROJECTS_PREFIX}/:projectId/${SEQUENCES}`;
export const ROUTE_PIPELINE_JOB_SEQUENCES_MAIN = `${ROUTE_PIPELINE_JOB_SEQUENCES_LIST}/:pipelineJobSequenceId`;
