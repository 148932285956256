import { Observable, of } from "rxjs";
import { SiteSummary } from "wordparrot-types";
import { ajax } from "rxjs/ajax";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";

import { ApiResponse, _delete, get, post, put } from "lib/api";
import { Dns, Seo, Site, Social, Theme } from "./interface";
import { categoryStore } from "state/category/store";
import { domainService } from "services/Domain";
import { imageService } from "state/image/service";
import { sessionService } from "state/session/service";
import { site$, siteQuery } from "./query";
import { siteStore } from "./store";

import * as apiConstants from "constants/api";
import * as authConstants from "constants/auth";
import * as bannerConstants from "constants/banners";
import * as dnsConstants from "constants/dns";
import * as hubConstants from "constants/hub";
import * as siteConstants from "constants/sites";

export class SiteService {
  constructor(public siteStore) {}

  siteSearch(domain: string): Observable<{
    is_wordparrot: boolean;
    siteSummary: SiteSummary;
  }> {
    const url = `https://${domain}/${siteConstants.SITE_SEARCH}`;
    return get<
      ApiResponse<{
        is_wordparrot: boolean;
        siteSummary: SiteSummary;
      }>
    >(url).pipe(
      map((response) => {
        if (!response.result) {
          throw new Error("SiteService.siteSearch(): result failed");
        }
        if (!response.data.is_wordparrot) {
          throw new Error(
            "SiteService.siteSearch(): not a valid wordparrot server search response",
          );
        }
        return response.data;
      }),
    );
  }

  fetchSites(): Observable<Site[]> {
    return get<ApiResponse<Site[]>>(
      `${domainService.apiRoot}/${siteConstants.SITES}`,
    ).pipe(map((response) => response.data));
  }

  fetchSite(config?: { siteId: string }): Observable<ApiResponse<Site>> {
    let url = `${domainService.apiRoot}/${siteConstants.SITES}/${siteConstants.FETCH}`

    if (config?.siteId) {
      url += `?siteId=${config.siteId}`;
    }

    return get<ApiResponse<Site>>(url).pipe(
      map((response) => {
        if (!response.result) {
          throw new Error(response.message);
        }
        this.upsertSiteInformation(response.data);
        this.setActive(response.data.id);
        return response;
      }),
    );
  }

  fetchSiteVersion(): Observable<ApiResponse<{ apiVersion: string }>> {
    const url = `${domainService.apiRoot}/${siteConstants.DATABASE_OPTIONS}/${siteConstants.DATABASE_OPTIONS_API_VERSION}`;
    return get<ApiResponse<{ apiVersion: string }>>(url)
  }

  // Send a single request to the new subdomain to initiate SSL cert creation while the loading screen loads. If the request fails that is ok - we wish to spur the issuing of a new SSL certificate with this request.
  initiateSSLRequest(domain: string): Observable<any> {
    return get(domain).pipe(
      catchError((e) => {
        return of({});
      }),
    );
  }

  upsertSiteInformation(site: Site) {
    siteStore.upsertMany([site]);
    categoryStore.upsertMany(site.categories || []);
    imageService.upsert(site.banners?.map((banner) => banner.image) || []);
  }

  fetchSiteWithDns(id: string): Observable<Site> {
    return get<ApiResponse<Site>>(
      `${domainService.apiRoot}/${siteConstants.SITES}/${id}/${siteConstants.SITE_WITH_DNS}`,
    ).pipe(
      map((response) => response.data),
      tap((site) => {
        this.siteStore.upsert(site.id, site);
        this.siteStore.setActive(site.id);
      }),
    );
  }

  update(formValue: Site): Observable<Site> {
    const siteId = siteQuery.getActiveId();
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${siteConstants.SITES}/${siteId}`,
      {
        body: formValue,
      },
    ).pipe(
      map((response) => response.data),
      mergeMap(() => this.fetchSite()),
      map((response) => response.data)
    );
  }

  updateStore(site: Site) {
    this.siteStore.upsert(site.id, site);
    this.siteStore.setActive(site.id);
  }

  setActive(id: string) {
    this.siteStore.setActive(id);
  }

  subscribeToSite(siteId: string) {
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${siteConstants.SITES}/${siteId}`,
      {
        body: {
          siteId,
        },
      },
    ).pipe(
      map((response) => response.data),
      mergeMap(() => this.fetchSite()),
    );
  }
}

export const siteService = new SiteService(siteStore);

class SiteHubService {
  constructor(public siteStore) {}
  register(siteId: string): Observable<{
    hubSiteId: string;
  }> {
    const url = `${domainService.apiRoot}/${hubConstants.HUB_SITES}/${hubConstants.REGISTER_SITE}`;

    return post<
      ApiResponse<{
        hubSiteId: string;
      }>
    >(url, {
      body: {
        siteId,
      },
    }).pipe(map((response) => response.data));
  }
}

export const siteHubService = new SiteHubService(siteStore);

class SiteSubscriptionService {
  constructor(public siteStore) {}

  fetchSiteSubscriptions(): Observable<Site[]> {
    return get<ApiResponse<Site[]>>(
      `${domainService.apiRoot}/${siteConstants.SITES}/${siteConstants.SUBSCRIPTIONS}`,
    ).pipe(map((response) => response.data));
  }

  fetchOpenSitesByDomain(domain: string): Observable<Site[]> {
    let url = `${domainService.apiRoot}/${siteConstants.SITES}/${siteConstants.SUBSCRIPTIONS}/${siteConstants.SEARCH}`;

    url += `?domain=${encodeURIComponent(domain)}`;

    return get<ApiResponse<Site[]>>(url).pipe(map((response) => response.data));
  }

  subscribeToSite(siteId: string) {
    return post<ApiResponse<void>>(
      `${domainService.apiRoot}/${siteConstants.SITES}/${siteConstants.SUBSCRIPTIONS}/${siteConstants.SUBSCRIBE}`,
      {
        body: {
          siteId,
        },
      },
    ).pipe(map((response) => response.data));
  }

  unsubscribeFromSite(siteId: string) {
    return post<ApiResponse<void>>(
      `${domainService.apiRoot}/${siteConstants.SITES}/${siteConstants.SUBSCRIPTIONS}/${siteConstants.UNSUBSCRIBE}`,
      {
        body: {
          siteId,
        },
      },
    ).pipe(map((response) => response.data));
  }
}

export const siteSubscriptionService = new SiteSubscriptionService(siteStore);

export class ThemeService {
  update(formValue): Observable<Theme> {
    return put<{
      result: boolean;
      data: Site;
    }>(`${domainService.apiRoot}/${siteConstants.SITE_THEME}`, {
      body: formValue,
    }).pipe(
      map((response) => response.data),
      tap((site) => {
        siteService.updateStore(site);
      }),
      map((site) => site.theme),
    );
  }
}

export const themeService = new ThemeService();

export class SocialService {
  update(formValue: Social): Observable<any> {
    return put<ApiResponse<Site>>(
      `${domainService.apiRoot}/${siteConstants.SITE_SOCIAL}`,
      {
        body: formValue,
      },
    ).pipe(
      map((response) => response.data),
      tap((site) => {
        siteService.updateStore(site);
      }),
    );
  }
}

export const socialService = new SocialService();

export class SeoService {
  update(formValue: Seo): Observable<any> {
    return put<ApiResponse<Site>>(
      `${domainService.apiRoot}/${siteConstants.SITE_SEO}`,
      { body: formValue },
    ).pipe(
      map((response) => response.data),
      tap((site) => {
        siteService.updateStore(site);
      }),
    );
  }
}

export const seoService = new SeoService();

export class DnsService {
  checkDomainAvailability(
    domain: string,
  ): Observable<{ availability: boolean }> {
    return get<{ availability: boolean }>(
      `${domainService.apiRoot}/${authConstants.AUTH}/${siteConstants.SITES}/${siteConstants.SITE_AVAILABILITY}?domain=${domain}`,
    );
  }

  verify(config: { domain: string }): Observable<Site> {
    const siteId = siteQuery.getActiveId() || ("" as any);
    const { domain } = config;

    return post<ApiResponse<void>>(
      `${domainService.apiRoot}/${siteConstants.DNS}/${dnsConstants.DNS_VERIFY}`,
      {
        body: {
          domain,
        },
      },
    ).pipe(
      map((response) => response.data),
      switchMap(() => siteService.fetchSiteWithDns(siteId)),
    );
  }

  migrate(config: {
    domainPending: string;
    isSubdomainPending: boolean;
  }): Observable<Site> {
    const siteId = siteQuery.getActiveId() || ("" as any);
    const { domainPending, isSubdomainPending } = config;

    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${siteConstants.DNS}/${dnsConstants.DNS_MIGRATE}`,
      {
        body: {
          domainPending,
          isSubdomainPending,
        },
      },
    ).pipe(
      map((response) => response.data),
      switchMap(() => siteService.fetchSiteWithDns(siteId)),
    );
  }

  cancelMigration(): Observable<Site> {
    const siteId = siteQuery.getActiveId() || ("" as any);

    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${siteConstants.DNS}/${dnsConstants.DNS_CANCEL_MIGRATION}`,
      {
        body: {},
      },
    ).pipe(
      map((response) => response.data),
      switchMap(() => siteService.fetchSiteWithDns(siteId)),
    );
  }
}

export const dnsService = new DnsService();
