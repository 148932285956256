import { Site } from 'wordparrot-types';
import { firstValueFrom } from "rxjs";
import {
  useQuery,
} from "react-query";

import {
  ApiResponse,
} from "lib/api";
import { QueryOptions } from "lib/request";
import { siteService } from "state/site/service";

export const useFetchSite = (
  payload?: {
    siteId: string
  },
  options?: QueryOptions<
    ["siteService.fetchSite", typeof payload],
    ApiResponse<Site>
  >,
) => {
  return useQuery(
    ["siteService.fetchSite", payload],
    () => firstValueFrom(
      siteService.fetchSite(payload)
    ),
    {
      ...options,
    },
  );
};


export const useSiteVersion = (
  payload?: {
    siteId?: string
  },
  options?: QueryOptions<
    ["siteService.fetchSiteVersion", typeof payload],
    ApiResponse<{ apiVersion: string }>
  >,
) => {
  return useQuery(
    ["siteService.fetchSiteVersion", payload],
    () => firstValueFrom(siteService.fetchSiteVersion()),
    {
      ...options,
      retry: false,
    },
  );
};
