import { useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import makeStyles from "@mui/styles/makeStyles";

import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Grid2 from '@mui/material/Grid2';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { deviceService } from "services/Capacitor";
import { profile$ } from "state/session/query";
import { site$ } from "state/site/query";

import DashboardAvatar from "modules/dashboard/Atoms/DashboardAvatar";
import DashboardBreadcrumbs from "modules/dashboard/Atoms/DashboardBreadcrumbs";
import DashboardDrawer from "modules/dashboard/Organisms/DashboardDrawer";
import DashboardMobileNavigation from "modules/dashboard/Organisms/DashboardMobileNavigation";

import * as routingConstants from "lib/routing";

export const barFixedTopHeight = 55;
export const barFixedBottomHeight = 50;
export const desktopOffset = barFixedTopHeight + barFixedBottomHeight;
export const drawerWidth = 200;
export const mainWidth = drawerWidth;

const useStyles = makeStyles((theme) => ({
  backBox: {
    padding: 10,
  },

  appBarContainer: {
    height: 65,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },

    "& .MuiToolbar-root": {
      display: "flex",
      justifyContent: "space-between",
      minHeight: 65,
    },
  },

  main: {
    marginLeft: mainWidth,
    padding: 0,
    paddingBottom: theme.spacing(6),
    background: theme.palette.background.default,

    [theme.breakpoints.down("lg")]: {
      width: `100%`,
      marginLeft: 0,
    },
  },

  mainShifted: {
    marginLeft: 0,
    [theme.breakpoints.down("lg")]: {
      width: drawerWidth,
      marginLeft: drawerWidth,
    },
  },

  drawer: {
    flexShrink: 0,
  },

  backgroundMain: {
    backgroundColor: theme.palette.primary.main,
  },

  title: {
    flexGrow: 1,
  },

  contentShift: {
    marginLeft: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth,
    },
  },

  container: {
    minHeight: "100vh",
    padding: 0,
  },

  boxExterior: {
    position: "fixed",
    width: "100%",
    zIndex: 1201, // Must be higher priority than sidebar

    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },

  box: {
    height: barFixedTopHeight,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.primary.main,
    color: "#ffffff",
  },

  profilePanel: {
    display: "inline-block",
    alignItems: "center",
    marginLeft: 15,
    padding: "5px 10px",
    border: "1px solid #eeeeee",
    boxShadow: "none",
  },

  profileName: {
    fontSize: ".85rem",
  },

  profileEmail: {
    fontSize: ".7rem",
    opacity: 0.5,
  },

  settingsContainer: {
    marginRight: 0,
  },

  secondaryGrid: {
    display: "flex",
    alignItems: "center",
  },

  secondaryBox: {
    height: barFixedBottomHeight,
    position: "relative",
    top: -5,
    zIndex: 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: "#ffffff",
    borderBottom: "2px solid #eeeeee",
  },

  siteTitle: {
    marginBottom: 0,
    paddingTop: 5,
    paddingLeft: 30,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    textAlign: "center",
    fontSize: 13,
  },

  siteLogo: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 35,
    width: 35,
  }
}));

const DashboardShell: FunctionComponent<{
  children: ReactElement;
}> = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const [drawer, setDrawer] = useState(false);
  const profile = useObservable(profile$);
  const site = useObservable(site$);

  useEffect(() => {
    setDrawer(false);
  }, [location]);

  const drawerHandler = useCallback(() => {
    setDrawer(!drawer);
  }, [drawer]);

  const logoImgSrc = useMemo(() => {
    if (process.env.REACT_APP_LOGO_DASHBOARD) {
      return process.env.REACT_APP_LOGO_DASHBOARD
    }

    if (site?.logo) {
      return site.logo
    }

    return "/img/logo_white_sm.png"
  }, [site?.logo])

  return (
    <>
      <Box className={classes.boxExterior}>
        <Box className={classes.box}>
          <Grid container>
            <Grid item xs={6}>
              <Box display="flex" alignItems={"center"}>
                <div
                className={classes.siteLogo}
                style={{
                  backgroundImage: `url('${logoImgSrc}')`,
                }}
                >
                </div>
                <Typography className={classes.siteTitle}>
                  {site?.title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              {profile && (
                <Box display="flex" justifyContent="flex-end">
                  <DashboardAvatar id={profile.id} />
                  <div>
                    <Typography className={classes.profileName}>
                      {profile.name}
                    </Typography>
                    <Typography className={classes.profileEmail}>
                      {profile.email}
                    </Typography>
                  </div>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.secondaryBox}>
          <Grid container className={classes.secondaryGrid}>
            <Grid item xs={9} sx={{ paddingLeft: "15px" }}>
              <DashboardBreadcrumbs />
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <NavLink to={routingConstants.ROUTE_DASHBOARD_SETTINGS}>
                  <Button
                    size="small"
                    endIcon={<SettingsIcon />}
                    variant="outlined"
                  >
                    Settings
                  </Button>
                </NavLink>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <DashboardDrawer drawer={drawer} />
      <main className={classes.main}>
        {!deviceService.isMobile && (
          <div className={classes.appBarContainer}>
            <AppBar>
              <Toolbar>
                <div>
                  <img
                    src={"/img/logo_white_sm.png"}
                    style={{ width: "120px" }}
                    alt="logo"
                  />
                </div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={drawerHandler}
                  edge="start"
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </div>
        )}
        <Grid2 container className={classes.container}>
          <Grid2 size={{ xl: 10 }}>{children}</Grid2>
        </Grid2>
      </main>
      {deviceService.isMobile && <DashboardMobileNavigation />}
    </>
  );
};

export default DashboardShell;
