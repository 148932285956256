import React from "react";

const TabPanel = ({ name, value, index, ...props }) => {
  return (
    <div
      {...props}
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-${index}`}
      aria-labelledby={`${name}-${index}`}
    >
      {value === index && props.children}
    </div>
  );
};

export default TabPanel;
