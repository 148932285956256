export const ENTRIES = "entries";
export const ITEMS = "items";
export const DASHBOARD = "dashboard";
export const BOOKMARKS = "bookmarks";
export const BOOKMARKS_REMOVED = "bookmarks-removed";

export const ENTRY_ITEM_DOWNLOADS = "entry-item-downloads";
export const ENTRY_LINKS = "entry-links";
export const WITH_CATEGORIES = "categories";
export const CHECK = "check";
export const HIGHLIGHT = "highlight";

export const CSV = "csv";

export const ENTRY_ITEM_STATUS_ERROR = "error";
export const ENTRY_ITEM_STATUS_ACTIVE = "active";

export const ENTRY_ITEM_STATUS_NOTIFICATION_ACTIVE = "active";
export const ENTRY_ITEM_STATUS_NOTIFICATION_TRASHED = "trashed";
export const ENTRY_ITEM_STATUS_NOTIFICATION_DELETED = "deleted";
export const ENTRY_ITEM_STATUS_NOTIFICATION_NOT_FOUND = "not_found";

export const STATUS_PUBLISH = "publish";
export const STATUS_DRAFT = "draft";
export const STATUS_PRIVATE = "private";
export const STATUS_TRASH = "trash";
export const STATUS_NOT_TRASH = "not_trash";

export const TYPE_URL = "url";
export const TYPE_FILE = "file";

export const ACTION_DOWNLOAD = "download";
export const TITLES = "titles";
