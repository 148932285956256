import wordparrotTheme from "theme/theme";

import { Theme } from "state/site/interface";

const useCustom = (custom?: Theme) => {
  if (!custom) {
    return wordparrotTheme;
  }

  return {
    ...wordparrotTheme,
    palette: {
      ...wordparrotTheme.palette,
      primary: {
        ...wordparrotTheme.palette.primary,
        main: custom.primaryMain,
        dark: custom.primaryDark,
        light: custom.primaryLight,
        contrastText: custom.primaryContrastText,
      },
      secondary: {
        ...wordparrotTheme.palette.secondary,
        main: custom.secondaryMain,
        dark: custom.secondaryDark,
        light: custom.secondaryLight,
        contrastText: custom.secondaryContrastText,
      },
      info: {
        ...wordparrotTheme.palette.info,
        main: custom.infoMain,
      },
      success: {
        ...wordparrotTheme.palette.success,
        main: custom.successMain,
      },
      warning: {
        ...wordparrotTheme.palette.warning,
        main: custom.warningMain,
      },
      error: {
        ...wordparrotTheme.palette.error,
        main: custom.errorMain,
      },
      text: {
        ...wordparrotTheme.palette.text,
        primary: custom.textPrimary,
        secondary: custom.textSecondary,
        disabled: custom.textDisabled,
      },
      divider: custom.divider,
      background: {
        default: custom.backgroundDefault,
        paper: custom.backgroundPaper,
      },
    },
    custom: {
      banner: "black",
      footer: custom.footer,
    },
  };
};

export default useCustom;
