import { Observable, of } from "rxjs";
import { PaginationResponse, PaginatorPlugin } from "@datorama/akita";
import {
  Pipeline,
  Prompt,
  PromptInstance,
  PromptRecipient,
  RepositoryFile,
  User,
} from "wordparrot-types";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  postMultipart,
  put,
} from "lib/api";
import {
  PromptDefaultRecipientState,
  PromptInstanceState,
  PromptRecipientState,
  PromptState,
} from "state/session/interface";
import { breadcrumbService } from "services/Breadcrumb";
import { domainService } from "services/Domain";
import { promptStore } from "./store";

import * as apiConstants from "constants/api";
import * as promptConstants from "constants/prompts";

export interface PromptSubmission {
  promptId: string;
  promptInstanceId: string;
  promptRecipientId?: string;
  token?: string;
  data: any;
  files?: any[];
}

export class PromptService {
  fetch(config: PromptState): Observable<PaginationResponse<Prompt>> {
    let url = `${domainService.apiRoot}/${promptConstants.PROMPTS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (config.pipelineGroupId) {
      url += `&pipelineGroupId=${config.pipelineGroupId}`;
    }

    return get<PaginationResponse<Prompt>>(url).pipe(
      tap((response) => {
        promptStore.upsertMany(response.data);
        breadcrumbService.set(response.data, "prompt");
      }),
    );
  }

  fetchDefaultRecipients(
    config: PromptDefaultRecipientState,
  ): Observable<PaginationResponse<PromptRecipient>> {
    let url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${config.promptId}/${promptConstants.RECIPIENTS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (config.promptId) {
      url += `&promptId=${config.promptId}`;
    }

    return get<PaginationResponse<PromptRecipient>>(url);
  }

  fetchOne(config: { promptId: string }): Observable<Prompt> {
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${config.promptId}`;
    return get<ApiResponse<Prompt>>(url).pipe(
      map((response) => response.data),
      tap((prompt) => {
        breadcrumbService.set([prompt], "prompt");
      }),
    );
  }

  fetchOneByIdentifier(promptId: string): Observable<PromptInstance> {
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${promptConstants.IDENTIFIER}/${promptId}`;
    return get<ApiResponse<PromptInstance>>(url).pipe(
      map((response) => response.data),
      tap((prompt) => {
        breadcrumbService.set([prompt], "prompt");
      }),
    );
  }

  fetchDownstreamPipelines(config: {
    promptId: string;
  }): Observable<PaginationResponse<Pipeline>> {
    const { promptId } = config;
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${promptId}/${promptConstants.DOWNSTREAM}`;
    return get<PaginationResponse<Pipeline>>(url);
  }

  create(body: Partial<Prompt>): Observable<{ id: string }> {
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}`;

    return post<ApiResponse<{ id: string }>>(url, {
      body,
    }).pipe(map((response) => response.data));
  }

  update(body: Prompt): Observable<void> {
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${body.id}`;
    return put<ApiResponse<void>>(url, {
      body,
    }).pipe(map((response) => response.data));
  }

  delete(id: string): Observable<void> {
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${id}`;
    return _delete<ApiResponse<void>>(url).pipe(
      map((response) => response.data),
    );
  }

  addDownloadFile(id: string, formData: FormData): Observable<void> {
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${id}/${promptConstants.DEFAULT_FILE}`;
    return postMultipart<ApiResponse<void>>(url, {
      body: formData,
    }).pipe(map((response) => response.data));
  }

  deleteDownloadFile(id: string, formData: FormData): Observable<void> {
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${id}/${promptConstants.DEFAULT_FILE}`;
    return _delete<ApiResponse<void>>(url).pipe(
      map((response) => response.data),
    );
  }

  addDownstreamPipeline(config: {
    promptId: string;
    pipelineId: string;
  }): Observable<null> {
    const { promptId, pipelineId } = config;
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${promptId}/${promptConstants.DOWNSTREAM}`;
    return post<ApiResponse<null>>(url, {
      body: {
        promptId,
        pipelineId,
      },
    }).pipe(map((response) => response.data));
  }

  deleteDownstreamPipeline(config: {
    promptId: string;
    pipelineId: string;
  }): Observable<null> {
    const { promptId, pipelineId } = config;
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${promptId}/${promptConstants.DOWNSTREAM}/${pipelineId}`;
    return _delete<ApiResponse<null>>(url).pipe(
      map((response) => response.data),
    );
  }

  addDefaultRecipient(config: {
    promptId: string;
    userId: string;
  }): Observable<null> {
    const { promptId, userId } = config;
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${promptId}/${promptConstants.RECIPIENTS}`;
    return post<ApiResponse<null>>(url, {
      body: {
        promptId,
        userId,
      },
    }).pipe(map((response) => response.data));
  }

  deleteDefaultRecipient(config: {
    promptId: string;
    userId: string;
  }): Observable<null> {
    const { promptId, userId } = config;
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${promptId}/${promptConstants.RECIPIENTS}/${userId}`;
    return _delete<ApiResponse<null>>(url).pipe(
      map((response) => response.data),
    );
  }
}

export const promptService = new PromptService();

export class PromptInstanceService {
  fetch(
    config: PromptInstanceState,
  ): Observable<PaginationResponse<PromptInstance>> {
    let url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${config.promptId}/${promptConstants.INSTANCES}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (config.promptId) {
      url += `&promptId=${config.promptId}`;
    }

    return get<PaginationResponse<PromptInstance>>(url);
  }

  fetchOne(config: {
    promptId: string;
    promptInstanceId: string;
  }): Observable<PromptInstance> {
    const { promptId, promptInstanceId } = config;
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${promptId}/${promptConstants.INSTANCES}/${promptInstanceId}`;
    return get<ApiResponse<PromptInstance>>(url).pipe(
      map((response) => response.data),
    );
  }

  create(config: { promptId: string }): Observable<PromptInstance> {
    const { promptId } = config;

    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS}/${promptId}/${promptConstants.INSTANCES}`;

    return post<ApiResponse<PromptInstance>>(url, {
      body: {},
    }).pipe(map((response) => response.data));
  }
}

export const promptInstanceService = new PromptInstanceService();

export interface PromptSubmissionWithFormData extends PromptSubmission {
  promptRecipientId: string;
  formData?: FormData;
}

export class PromptDisplayService {
  fetchOne(config: {
    promptId: string;
    uniqId: string | null;
    token: string | null;
  }): Observable<PromptInstance> {
    const { promptId, token, uniqId } = config;

    let url = `${domainService.apiRoot}/${promptConstants.PROMPTS_DISPLAY}/${promptId}?uniqId=${uniqId}`;

    if (token) {
      url += `&token=${token}`;
    }

    return get<ApiResponse<PromptInstance>>(url).pipe(
      map((response) => response.data),
    );
  }

  submitForm(
    body: PromptSubmission,
  ): Observable<{ promptRecipientId: string }> {
    const { promptId, promptInstanceId, data } = body;
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS_DISPLAY}/${promptId}/${promptConstants.INSTANCES}/${promptInstanceId}`;
    return post<ApiResponse<{ promptRecipientId: string }>>(url, {
      body: { promptId, promptInstanceId, data },
    }).pipe(map((response) => response.data));
  }

  submitFormFiles(config: PromptSubmissionWithFormData): Observable<null> {
    const { promptId, promptInstanceId, promptRecipientId, formData } = config;
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS_DISPLAY}/${promptId}/${promptConstants.INSTANCES}/${promptInstanceId}/${promptConstants.RECIPIENTS}/${promptRecipientId}/${promptConstants.FILES}`;
    return postMultipart<ApiResponse<null>>(url, {
      body: formData,
    }).pipe(map((response) => response.data));
  }

  markCompleted(config: PromptSubmission): Observable<null> {
    const { promptId, promptInstanceId, promptRecipientId } = config;
    const url = `${domainService.apiRoot}/${promptConstants.PROMPTS_DISPLAY}/${promptId}/${promptConstants.INSTANCES}/${promptInstanceId}/${promptConstants.RECIPIENTS}/${promptRecipientId}/${promptConstants.MARK_COMPLETED}`;
    return post<ApiResponse<null>>(url, {
      body: {},
    }).pipe(map((response) => response.data));
  }
}

export const promptDisplayService = new PromptDisplayService();

export class PromptRecipientService {
  fetch(
    config: PromptRecipientState,
  ): Observable<PaginationResponse<PromptRecipient>> {
    let url = `${domainService.apiRoot}/${promptConstants.PROMPT_RECIPIENTS_API}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (config.promptId) {
      url += `&promptId=${config.promptId}`;
    }

    if (config.promptInstanceId) {
      url += `&promptInstanceId=${config.promptInstanceId}`;
    }

    if (config.startTime) {
      url += `&startTime=${config.startTime}`;
    }

    if (config.endTime) {
      url += `&endTime=${config.endTime}`;
    }

    return get<PaginationResponse<PromptRecipient>>(url);
  }

  fetchOne(promptRecipientId: string): Observable<PromptRecipient> {
    const url = `${domainService.apiRoot}/${promptConstants.PROMPT_RECIPIENTS_API}/${promptRecipientId}`;
    return get<ApiResponse<PromptRecipient>>(url).pipe(
      map((response) => response.data),
    );
  }
}

export const promptRecipientService = new PromptRecipientService();
