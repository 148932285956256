import { firstValueFrom } from "rxjs";
import { useHistory, useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import { useQuery } from "react-query";
import React, {
  FunctionComponent,
  useCallback,
} from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { site$ } from "state/site/query";
import { siteService } from "state/site/service";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import { SidebarSection, dynamicIcon, sections } from "./sections";
import { drawerWidth } from "modules/dashboard/Pages/Dashboard";

import CheckPermissions from "modules/_common/atoms/CheckPermissions";

import * as routingConstants from "lib/routing";
import { useSiteVersion } from "api/site";

const sidebarColor = "#232323";

const useStyles = makeStyles((theme) => ({
  outer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",

    [theme.breakpoints.up("lg")]: {
      padding: "90px 0px 15px 0px",
    },
  },

  appPanel: {
    minHeight: 70,
    padding: "15px 10px 10px 10px",
    textAlign: "center",
  },

  divider: {
    opacity: 0.5,
  },

  iconPanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "28px 0px 15px 0px",
  },

  avatar: {
    width: 35,
    height: 25,
    marginTop: 8,
    marginBottom: 40,
  },

  icon: {
    padding: 2,
    color: sidebarColor,
    fontSize: 28,
    border: `2px solid transparent`,
    borderRadius: 6,
  },

  exitIcon: {
    color: sidebarColor,
    cursor: "pointer",
  },

  highlightedIcon: {
    border: `2px solid ${sidebarColor}`,
    opacity: 1,
  },

  linkPanel: {
    display: "flex",
    flexDirection: "column",
    width: drawerWidth,
    height: "100vh",
  },

  titlePanel: {
    display: "flex",
    alignItems: "center",
    minHeight: 74,
    padding: 20,
  },

  title: {
    fontWeight: 600,
  },

  linkPanelLinks: {
    display: "flex",
    flexDirection: "column",
    padding: 15,
  },

  description: {
    fontSize: ".75rem",
    opacity: 0.5,
  },

  logoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,

    "& img": {
      maxWidth: 75,
      maxHeight: 75,
    },
  },

  inner: {
    flexGrow: 1,
    padding: "30px 0 30px 0px",
    color: sidebarColor,
  },

  list: {
    width: "100%",
    margin: 0,
    padding: 0,
  },

  optionList: {
    marginTop: 15,
  },

  listItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
  },

  pageLink: {
    marginBottom: 5,
    padding: "4px 12px",
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: "none",
    border: "1px solid transparent",
    borderRadius: 20,
    transition: ".3s",

    "&:hover": {
      textDecoration: "none",
      background: "#f7f7f7",
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },

  highlightedListItem: {
    background: "#f6f8fb",
  },

  banner: {
    display: "flex",
    flexDirection: "column-reverse",
    flexGrow: 1,
    margin: 20,
  },

  bannerInner: {
    padding: 15,
    textAlign: "center",
    background: theme.palette.background.default,
    border: "1px solid #eeeeee",
    borderRadius: 6,
  },

  bannerText: {
    marginBottom: 15,
  },

  sectionBox: {
    width: "100%",
    padding: "5px 10px",
    cursor: "pointer",
    opacity: 0.5,
    transition: ".3s",

    "&:hover": {
      opacity: 1,
    },
  },

  sectionName: {
    position: "relative",
    top: 2.5,
    paddingLeft: 10,
    color: sidebarColor,
    fontSize: 14,
    textTransform: "uppercase",
  },

  highlightedSectionName: {
    opacity: 1,
  },

  siteTitle: {
    marginBottom: 0,
    padding: 10,
    color: sidebarColor,
    fontWeight: 600,
    textAlign: "center",
    fontSize: 13,
  },

  versionContainer: {
    paddingBottom: 15,
    textAlign: "center",
  },

  version: {
    color: sidebarColor,
  },
}));

interface Props {
  showTopPanel?: boolean;
}

const isHighlighted = (location, section: SidebarSection): boolean => {
  if (location.pathname.includes(section.route)) return true;

  if (Array.isArray(section.pathMatch)) {
    const routeSegments = location.pathname.split("/");

    return routeSegments.some((segment) =>
      section.pathMatch?.includes(segment),
    );
  }

  return false;
};

const DashboardSidebar: FunctionComponent<Props> = ({ showTopPanel }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const site = useObservable(site$);

  const { data: versionResponse } = useSiteVersion();

  const handleClickIcon = useCallback(
    (section: SidebarSection) => () => {
      if (section.id === "logout") {
        window.location.pathname = routingConstants.ROUTE_LOGOUT;
      } else {
        history.push(section.route);
      }
    },
    [history],
  );

  const handleToFeaturedGroups = useCallback(() => {
    history.push(routingConstants.ROUTE_FEATURED_GROUP_LIST_LATEST);
  }, [history]);

  return (
    <div className={classes.outer}>
      <div>
        {showTopPanel && (
          <>
            <div className={classes.appPanel}>
              <img
                src={"/img/logo_sm.png"}
                style={{ width: "120px" }}
                alt="logo"
              />
            </div>
            <div>
              <Typography className={classes.siteTitle}>
                {site?.title}
              </Typography>
            </div>
            <Divider className={classes.divider}></Divider>
          </>
        )}
        <div className={classes.iconPanel}>
          <List className={clsx(classes.list)}>
            {sections
              .filter((section) => !section.hidden)
              .map((section) => {
                const item = (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={clsx(classes.sectionBox, {
                      [classes.highlightedSectionName]: isHighlighted(
                        location,
                        section,
                      ),
                    })}
                    onClick={handleClickIcon(section)}
                  >
                    {dynamicIcon(
                      section,
                      classes,
                      isHighlighted(location, section),
                    )}
                    <Typography className={classes.sectionName}>
                      {section.name}
                    </Typography>
                  </Box>
                );

                return (
                  <ListItem className={classes.listItem} key={section.id}>
                    {section.permissions?.length ? (
                      <CheckPermissions permissions={section.permissions}>
                        {item}
                      </CheckPermissions>
                    ) : (
                      <>{item}</>
                    )}
                  </ListItem>
                );
              })}
          </List>
        </div>
      </div>
      {versionResponse && (
        <div className={classes.versionContainer}>
          <div className={classes.banner}>
            <div className={classes.bannerInner}>
              <Typography variant="subtitle2" className={classes.bannerText}>
                {"See more of what you're missing."}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleToFeaturedGroups}
              >
                Latest
              </Button>
            </div>
          </div>
          <Typography variant="subtitle2" className={classes.version}>
            {versionResponse.data.apiVersion}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default DashboardSidebar;
